import React, { useEffect, useState } from "react";
import "./app.scss";
import { auth } from "./config/firebase";
import Login from "./component/Login";
import { getUserDetail } from "./component/LoginForm";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { ReactComponent as InformationIcon } from "./assets/information-icon.svg";
import { signOut } from "firebase/auth";
import { DesktopOutlined, LogoutOutlined, MobileOutlined } from "@ant-design/icons";

function App() {
  const [userId, setUserId] = useState();
  const [userDetail, setUserDetail] = useState();
  const [currentSelect, setCurrentSelect] = useState(1);

  useEffect(() => {
    if (currentSelect === 3) return;

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        return <Login setCurrentSelect={setCurrentSelect} />;
      }
    });
    return unsubscribe;
  }, [currentSelect]);

  const logoutUser = async () => {
    return await signOut(auth);
  };

  // auth.onAuthStateChanged(function (user) {
  //   if (user) {
  //     // User is sig.uid
  //     setUserId(user.uid);
  //     console.log(user);
  //   } else {
  //     console.log("run");
  //     // No user is signed in.
  //     return <Login />;
  //   }
  // });

  useEffect(() => {
    if (!userId) return;

    const fetch = async () => {
      const userDetail = (await getUserDetail(userId)).data();
      setUserDetail(userDetail);
    };
    fetch();
  }, [userId]);

  const items = [
    {
      key: "1",
      label: <p>Web</p>,
      icon: <DesktopOutlined />,
    },
    {
      key: "2",
      label: <p>Mobile</p>,
      icon: <MobileOutlined />,
    },
    {
      key: "3",
      label: <p>Logout</p>,
      icon: <LogoutOutlined />,
    },
  ];

  const handleMenuClick = (e) => {
    if (e.key === "3") {
      setCurrentSelect(1);
      setUserId();
      logoutUser();
    }

    setCurrentSelect(Number(e.key));
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const renderIframe = {
    1: () => (
      <iframe
        src={`https://dev-at-pos.web.app/payment-screen/${userDetail.id_restaurant}`}
      />
    ),
    2: () => (
      <iframe
        src={`https://dev-at-pos.web.app/payment-screen-mobile/${userDetail.id_restaurant}`}
      />
    ),
    3: () => <Login setCurrentSelect={setCurrentSelect} />,
  };

  if (userDetail)
    return (
      <div className="app">
        <Dropdown menu={menuProps} className="custom-dropdown">
          <Button>
            <InformationIcon />
          </Button>
        </Dropdown>

        {renderIframe[currentSelect]()}
      </div>
    );

  return <Login setCurrentSelect={setCurrentSelect} />;
}

export default App;
