import React from "react";
import { Row, Col } from "antd";
import LoginForm from "./LoginForm";

const Login = (props) => {
  return (
    <div className={"light"} style={{ height: "100vh", position: "relative" }}>
      <Row justify="center" className="align-items-stretch h-100" style={{ background: "white" }}>
        <Col xs={20} sm={20} md={24} lg={12}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                <img className="img-fluid" src="/logo-login.jpg" alt="" />
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                <div className="mt-4">
                  <LoginForm {...props} />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
