import { Alert, Button, Form, Input } from "antd";
import React, { useState } from "react";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";

const LoginForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const initialCredential = {
    email: "",
    password: "",
  };

  const onLogin = (values) => {
    props.setCurrentSelect(1);
    setLoading(true);

    signInWithEmailAndPassword(auth, values.email, values.password)
      .then(async (res) => {
        console.log(res);
        const profile = (await getProfile()).data();
        console.log(profile);
      })
      .catch((e) => {
        console.log(e);
        setMessage("パスワードが正しくありません。");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProfile = async () => {
    const user = auth.currentUser;
    if (user !== null) {
      const userDetail = await getUserDetail(user.uid);
      return userDetail;
    }
    throw new Error("Username/password is wrong");
  };

  return (
    <Form
      layout="vertical"
      name="login-form"
      initialValues={initialCredential}
      onFinish={onLogin}
    >
      {message && (
        <Alert
          style={{ marginBottom: 20 }}
          type="error"
          showIcon
          message={message}
        ></Alert>
      )}
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your email",
          },
          {
            type: "email",
            message: "Please enter a validate email!",
          },
        ]}
      >
        <Input
          id="email"
          prefix={<MailOutlined className="text-primary" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="text-primary" />}
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={loading}>
          Sign In
        </Button>
      </Form.Item>
    </Form>
  );
};

export const getUserDetail = async (uuid) => {
  const docRef = doc(db, "user", uuid);
  return getDoc(docRef);
};

export default LoginForm;
